import React from 'react';
import './birthday.scss';
import { Form, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Datetime from 'react-datetime';
import moment from 'moment';

import adminApi from '../../../services/admin.service';
import session from '../../../services/session.service';
import helper from '../../../services/helper.service';

import Stepper from './stepper';

const years = Array.from({ length: 1100 }, (_, i) => i + 1900);
const months = Array.from({ length: 12 }, (_, i) => (i < 9) ? '0' + (i + 1).toString() : (i + 1).toString());
const days = Array.from({ length: 31 }, (_, i) => (i < 9) ? '0' + (i + 1).toString() : (i + 1).toString());

class Birthday extends React.Component {
    constructor(props) {
        super();
        this.state = {
            showModal: true,
            birth: moment().format('YYYY-MM-DD'),
            birth_year: moment().format('YYYY'),
            birth_month: moment().format('MM'),
            birth_day: moment().format('DD'),
        };
    }

    componentDidMount() {
        const currentUser = session.get('currentUser');
        if (!currentUser) {
            this.props.history.push('/signup');
        } else {
            if (currentUser.birth === '') {
                this.setState({
                    birth: moment().format('YYYY-MM-DD'),
                    birth_year: moment().format('YYYY'),
                });
            } else {
                const date = new Date(currentUser.birth);
                this.setState({
                    birth: moment(date).format('YYYY-MM-DD'),
                    birth_year: moment(date).format('YYYY'),
                });
            }
        }
    }

    handleNext = async () => {
        const { birth, birth_year } = this.state;
        const currentUser = session.get('currentUser');
        const userid = currentUser.user_id;
        const current_year = moment().format('YYYY');
        const difference = Number(current_year) - Number(birth_year);
        if (difference < 18) {
            helper.showToast(
                'Error',
                'Members need to be over 18 years old',
                3
            );
            return;
        }
        let birth_data = {
            birth: birth,
            userid: userid,
            onboarding: 'birthday'
        };
        try {
            const updateRes = await adminApi.updateUser(birth_data);
            if (updateRes.status) {
                console.log('updateRes: ', updateRes.data);
                session.set('currentUser', updateRes.data);
                this.props.history.push('/signup/photo');
            } else {
                helper.showToast('Error', updateRes.data, 3);
            }
        } catch (error) {
            helper.showToast(
                'Error',
                'Failed to update birthday. Please try again',
                3
            );
        }
    };

    // handleDateChange = (date) => {
    //     console.log('date: ', moment(date).format('YYYY-MM-DD'),);
    //     if (!date) {
    //         helper.showToast('Warning', 'Please choose valid date and time', 2);
    //     } else {
    //         if (Date.parse(date)) {
    //             this.setState({
    //                 birth: moment(date).format('YYYY-MM-DD'),
    //                 birth_year: moment(date).format('YYYY'),
    //             });
    //         } else {
    //             helper.showToast(
    //                 'Warning',
    //                 'Please choose valid date and time',
    //                 2
    //             );
    //         }
    //     }
    // };

    onSelectYear = (e) => {
        const { birth_month, birth_day } = this.state;
        let birth = `${e.target.value}-${birth_month}-${birth_day}`;
        this.setState({
            birth: birth,
            birth_year: e.target.value,
        });
    };

    onSelectMonth = (e) => {
        const { birth_year, birth_day } = this.state;
        let birth = `${birth_year}-${e.target.value}-${birth_day}`;
        this.setState({
            birth: birth,
            birth_month: e.target.value,
        });
    };

    onSelectDay = (e) => {
        const { birth_year, birth_month } = this.state;
        let birth = `${birth_year}-${birth_month}-${e.target.value}`;
        this.setState({
            birth: birth,
            birth_day: e.target.value,
        });
    };

    goBack = (event) => {
        event.preventDefault();
        this.props.history.goBack();
    };

    render() {
        const { birth, birth_year, birth_month, birth_day } = this.state;
        return (
            <div className='d-flex flex-column birthday'>
                <Stepper isActive={2} />
                <div className='d-flex align-items-center m-auto'>
                    <Form className='d-flex flex-column signup-form'>
                        <div className='d-flex align-content-center justify-content-center'>
                            <img
                                className='auth-logo'
                                src={require('../../../assets/logo.png')}
                                alt=''
                            />
                        </div>
                        <h5 className='d-flex align-items-center justify-content-center text-center mt-2 mb-2 title'>
                            ADD YOUR BIRTHDAY
                        </h5>
                        <div className='d-flex align-items-center justify-content-center text-center mt-2 mb-4 description'>
                            THIS WON'T BE PART OF YOUR PUBLIC PROFILE
                        </div>
                        <div className='d-flex align-items-center justify-content-center mt-2'>
                            {/* <Datetime
                                className='datetimepicker'
                                dateFormat='YYYY-MM-DD'
                                timeFormat={false}
                                initialValue={birth}
                                onChange={this.handleDateChange}
                                onClose={this.handleDateChange}
                                value={birth}
                                initialViewMode='years'
                                closeOnSelect={true}
                                onBeforeNavigate={this.onBeforeNavigate}
                            /> */}
                            <Form.Group className='d-flex align-items-center'>
                                <Form.Control as='select'
                                    defaultValue={birth_month}
                                    onChange={this.onSelectMonth}
                                    className='m-1'
                                >
                                    {months.map((month) => (
                                        <option key={month} value={month}>
                                            {month}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Label column>
                                    M
                                </Form.Label>
                            </Form.Group>

                            <Form.Group className='d-flex align-items-center'>
                                <Form.Control as='select'
                                    defaultValue={birth_day}
                                    onChange={this.onSelectDay}
                                    className='m-1'
                                >
                                    {days.map((day) => (
                                        <option key={day} value={day}>
                                            {day}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Label column>
                                    D
                                </Form.Label>
                            </Form.Group>

                            <Form.Group className='d-flex align-items-center'>
                                <Form.Control as='select'
                                    defaultValue={birth_year}
                                    onChange={this.onSelectYear}
                                    className='m-1'
                                >
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Label column>
                                    Y
                                </Form.Label>
                            </Form.Group>
                        </div>

                        <div className='d-flex align-items-center justify-content-center mt-3'>
                            <Button
                                type='button'
                                className='btn btn-primary register-btn'
                                onClick={this.handleNext}
                            >
                                NEXT
                            </Button>
                        </div>
                        <div className='d-flex align-items-center justify-content-center mt-4 join-now'>
                            <Link to='#' onClick={this.goBack} className='join-now-link'>
                                <span>{'< '}GO BACK</span>
                            </Link>
                        </div>
                    </Form>
                </div>
                {/* <div className='background-overlay'></div> */}
            </div>
        );
    }
}

export default Birthday;
