import React from 'react';
import './login.scss';
import { Form, Button, Row, Col, Modal, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import adminApi from '../../../services/admin.service';
import session from '../../../services/session.service';
import helper from '../../../services/helper.service';

class GenerateLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
        };
    }

    handleInputChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (name === 'username') {
            // let isEmailValid;
            // if (!helper.validateEmail(value)) {
            //     isEmailValid = false;
            // } else {
            //     isEmailValid = true;
            // }
            // this.setState({
            //     isEmailValid: isEmailValid,
            // });
        }
        this.setState({
            [name]: value,
        });
    };

    handleLogin = async () => {
        if (
            this.state.username === null ||
            this.state.username === '' ||
            this.state.password === null ||
            this.state.password === ''
        ) {
            helper.showToast(
                'Warning',
                'Please enter a valid user name and password.',
                2
            );
        } else {
            try {
                if (this.state.username === 'generate' && this.state.password === 'generate1!') {
                    const generateUser = {
                        username: 'generate',
                        password: 'generate1!'
                    };
                    console.log('HERE: ', generateUser);
                    session.set('generate-user', generateUser);
                    window.location.href = '/admin/generate';
                } else {
                    helper.showToast(
                        'Warning',
                        'Please enter a valid user name and password.',
                        2
                    );
                }
            } catch (error) {
                helper.showToast('Error', 'Failed to log in.', 3);
            }
        }
    };

    onKeyPress = (event) => {
        if (event.charCode === 13) {
            this.btn.click();
        }
    }

    render() {
        const { username, password, isEmailValid } = this.state;
        return (
            <div className='d-flex flex-column login'>
                <div className='d-flex align-items-center flex-grow-1'>
                    <Form className='d-flex flex-column m-auto login-form'>
                        {/* <div className='d-flex align-content-center justify-content-center mb-4'>
                            <img
                                className='auth-logo'
                                src={require('../../../assets/logo.png')}
                                alt=''
                            />
                        </div> */}
                        <Form.Group className='mb-2'>
                            <Form.Control
                                type='text'
                                className='login-form-input'
                                placeholder='USER NAME'
                                name='username'
                                required
                                value={username}
                                onChange={this.handleInputChange}
                                onKeyPress={this.onKeyPress}
                                isValid={username !== ''}
                            />
                        </Form.Group>
                        <Form.Group className='mb-0'>
                            <Form.Control
                                type='password'
                                className='login-form-input'
                                placeholder='PASSWORD'
                                name='password'
                                required
                                value={password}
                                onChange={this.handleInputChange}
                                onKeyPress={this.onKeyPress}
                            />
                        </Form.Group>
                        <div className='d-flex align-items-start justify-content-end mb-3 forgotPass-container'>
                            {/* <Link to='/forgotpass'>
                                <span className='forgotPass'>FORGOT PASSWORD?</span>
                            </Link> */}
                        </div>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Button
                                type='button'
                                className='btn btn-primary login-btn'
                                onClick={this.handleLogin}
                                ref={node => (this.btn = node)}
                            >
                                LOGIN
                            </Button>
                        </div>
                        {/* <div className='d-flex align-items-center justify-content-center mt-3 join-now'>
                            NOT A MEMEBER YET?{' '}
                            <Link to='/signup' className='join-now-link'>
                                <span>&nbsp;JOIN NOW</span>
                            </Link>
                        </div> */}
                    </Form>
                </div>

                {/* <div className='d-flex flex-column flex-lg-row align-items-center justify-content-sm-center justify-content-lg-start footer'>
                    <div className='d-flex align-self-stretch align-items-center justify-content-sm-center justify-content-lg-start footer-section'>
                        <Link to={'#'} onClick={(e) => {
                            window.location.href = 'https://climateassembly.us';
                            e.preventDefault();
                        }} className='align-self-stretch items'>HOME</Link>
                        <Link to={'/terms'} className='align-self-stretch items'>TERMS</Link>
                        <Link to={'/privacy-policy'} className='align-self-stretch items'>PRIVACY</Link>
                    </div>
                    <div className='d-flex align-self-stretch align-items-center justify-content-sm-center justify-content-lg-start footer-section'>
                        <Link to={'#'} onClick={(e) => {
                            window.location.href = 'mailto:help@assembly.us';
                            e.preventDefault();
                        }} className='align-self-stretch items'>SUPPORT</Link>
                        <Link to={'#'} onClick={(e) => {
                            window.location.href = 'mailto:info@assembly.us';
                            e.preventDefault();
                        }} className='align-self-stretch items'>CONTACT</Link>
                    </div>

                </div> */}
                {/* <div className='background-overlay'></div> */}
            </div>
        );
    }
}

export default GenerateLogin;
