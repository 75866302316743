import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Row, Image, Modal } from 'react-bootstrap';
import userApi from '../../../services/user.service';
import session from '../../../services/session.service';

import './styles.scss';

import Content from '../../../components/content';
import ProfileCard from './ProfileCard';
import ClubCard from './ClubCard';
import MemberCard from './MemberCard';

function Dashboard() {
    const user = session.get('futureof-user');
    const [modalShow, setModalShow] = useState(false);

    window.onload = function () {
        getUser();
    };

    const getUser = async () => {
        try {
            const getUserRes = await userApi.getUser(user.user_id);
            if (getUserRes.status) {
                const results = getUserRes.data;

                session.set('futureof-user', results);
                if (results.phone_number === '') {
                    setModalShow(true);
                }
            } else {
                console.log('error: ', getUserRes.data);
            }
        } catch (error) {
            console.error('getUser: ', error);
        }
    };

    const Popup = () => {
        return (
            <Modal
                show={modalShow}
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                dialogClassName='popup'
            >
                <Modal.Body>
                    <Row className='text-center justify-content-center my-3 mx-3'>
                        <h5>
                            Thank you for joining Assembly.
                        </h5>
                    </Row>

                    <Row className='text-center justify-content-center my-3 mx-3'>
                        <h5>
                            You can enter and participate in your club(s) on the mobile app.
                        </h5>
                    </Row>

                    <Row className='text-center justify-content-center my-3 mx-3'>
                        <h5>
                            Please download the app and login now from the Apple
                            App Store.
                        </h5>
                    </Row>

                    <Row className='align-items-center justify-content-center my-3'>
                        <Link to='' target='_blank'>
                            <Image
                                src={require('../../../assets/img/appstore.png')}
                                style={{ width: '120px' }}
                            ></Image>
                        </Link>
                    </Row>

                    <Row className='align-items-center justify-content-center my-3'>
                        <Button
                            type='button'
                            className='btn btn-primary login-btn'
                            onClick={() => setModalShow(false)}
                        >
                            OK
                        </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    };
    return (
        <Content>
            <Row className="justify-content-md-start">
                <h4>MANAGER DASHBOARD</h4>
            </Row>
            {Popup()}
            <ClubCard />
            <MemberCard />
            <ProfileCard />
        </Content>
    );
}

export default Dashboard;
