import React, { useEffect, useState } from 'react';
import session from '../../../services/session.service';
import Content from '../../../components/content';
import { Row, Spinner } from 'react-bootstrap';
import adminApi from '../../../services/admin.service';
import UserClubCard from './ClubCard/join';

const JoinClub = () => {
    const user = session.get('futureof-user');
    const [clubs, setClubs] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectedClubs, setSelectedClubs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAllClubs();
        getClubsWithUserId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initSelected = (data) => {
        var selected = [];
        var selectedClubs = [];
        for (const idx in data) {
            const club = data[idx];
            selectedClubs.push(club);
            selected.push(club.club_id);
        }
        setSelected(selected);
        setSelectedClubs(selectedClubs);
    };

    const getJoinItem = (item) => {
        const itemIndex = selected.findIndex((r) => r === item.club_id);
        return itemIndex >= 0;
    };

    const getUserRole = (item) => {
        const itemIndex = selectedClubs.findIndex((r) => r.club_id === item.club_id);
        return itemIndex >= 0 ? selectedClubs[itemIndex].user_role : '';
    };

    const getClubsWithUserId = () => {
        adminApi
            .getClubsWithUserId(user.user_id)
            .then((response) => {
                if (response.status === true) {
                    let results = response.connect;
                    initSelected(results);
                } else {
                    initSelected([]);
                }
            })
            .catch((error) => {
                console.log('err: ', error);
            });
    };

    const getAllClubs = () => {
        setLoading(true);
        adminApi
            .getAllClubs()
            .then((response) => {
                setLoading(false);
                if (response.status === true) {
                    var results = response.data;

                    setClubs(results);
                }
            })
            .catch((error) => {
                console.log('err: ', error);
                setLoading(false);
            });
    };

    const spinner = () => {
        return (
            <Row
                className="justify-content-center align-items-center my-auto"
                style={{ height: '50px' }}
            >
                <Spinner animation="border" />
            </Row>
        );
    };

    return (
        <Content>
            <Row className="justify-content-md-start">
                <h4>JOIN CLUBS</h4>
            </Row>
            {loading
                ? spinner()
                : clubs.map((club) => {
                      const joined = getJoinItem(club);
                      const user_role = getUserRole(club);
                      club.user_role = user_role;
                      return (
                          <UserClubCard
                              key={club.club_id}
                              club={club}
                              joined={joined}
                          />
                      );
                  })}
        </Content>
    );
};

export default JoinClub;
