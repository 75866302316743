import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import session from '../../../../services/session.service';
import adminApi from '../../../../services/admin.service';

import './styles.scss';

function ClubCard() {
    const club_info = session.get('futureof-club');
    const user = session.get('futureof-user');

    const [club, setClub] = useState(club_info);
    const [price, setPrice] = React.useState(10);

    useEffect(() => {
        if (club_info == null || club_info.club_id === null) {
            getClubInfo();
        } else {
            setClub(club_info);
        }
        getAllClubTiers();
    }, []);

    const getClubInfo = async () => {
        try {
            const response = await adminApi.getClubsWithUserId(user.user_id);
            if (response.status &&
                Array.isArray(response.connect) &&
                response.connect.length > 0) {
                session.set('futureof-club', response.connect[0]);
                setClub(response.connect[0]);
            }
        } catch (error) {
            console.log('err: ', error);
        }
    };

    const getAllClubTiers = () => {
        adminApi
            .getAllClubTiers()
            .then((response) => {
                if (response.status === true) {
                    const results = response.data;
                    const tier = results.find(
                        (item) => item.clubtier_name === club.memebership
                    );
                    setPrice(tier.price);
                }
            })
            .catch((error) => {
                console.log('err: ', error);
            });
    };

    return (
        <div className='club-card'>
            <Row className='justify-content-md-start'>
                <h5>CLUB INFORMATION</h5>
            </Row>
            <Col className='justify-content-md-start club-wrapper'>
                <div
                    className='club-img'
                >
                    {
                        club?.banner_url && (
                            <img src={club?.banner_url} style={{ width: '100%' }}></img>
                        )
                    }
                </div>
                <Row>
                    <Col sm={12} lg={8} className='club-container'>
                        <div className='club-label'>Club Name:</div>
                        <div className='club-name'>{club?.club_name}</div>
                        <div className='club-label'>Club Description:</div>
                        <div className='club-description'>{club?.description}</div>
                    </Col>
                    <Col sm={12} lg={4} className='club-container'>
                        <div className='club-label'>Tier:</div>
                        <div className='club-role'>{club?.memebership}</div>
                        <div className='club-label'>Cost:</div>
                        <div className='club-role'>
                            ${price} annual membership dues
                        </div>
                        {
                            club && club.user_role === 'manager' && (
                                <>
                                    <div className='club-label'>Members:</div>
                                    <div className='club-role'>
                                        {club?.maximum_number}
                                    </div>

                                    <div className='club-label' style={{ marginTop: 34 }}>
                                        Access Code:
                                    </div>
                                    <div className='club-role'>{club?.access_code}</div>
                                </>
                            )
                        }

                    </Col>
                </Row>
            </Col>
        </div>
    );
}
export default ClubCard;
