import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import adminApi from '../../../../services/admin.service';
import session from '../../../../services/session.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';
const placeHolderPng = require('../../../../assets/avatar/admin.jpg');

function ProfileCard() {
    const currentUser = session.get('futureof-user');
    const currentClub = session.get('futureof-club');
    const history = useHistory();

    const [customer, setCustomer] = useState(null);
    const [likeGainsAudios, setLikeGainsAudios] = useState(0);
    const [likeGainsPosts, setLikeGainsPosts] = useState(0);
    const [likeGainsRooms, setLikeGainsRooms] = useState(0);

    useEffect(() => {
        getCustomer();
        // getDatas(currentClub);
    }, []);

    const getCustomer = async () => {
        try {
            const res = await adminApi.getCustomer(currentUser.user_id);
            if (res.status) {
                setCustomer(res.data);
            }
        } catch (error) { }
    };

    const getDatas = async (club) => {
        if (club) {
            try {
                const getDataRes = await adminApi
                    .getDataWithClubId(club.club_id);
                if (getDataRes.status) {
                    let results = getDataRes.data;

                    console.log('results: ', results);
    
                    let audio_list = results.filter(
                        (a) => a.audio_id && a.audio_id !== '' && a.host_id === currentUser.user_id
                    );
                    let post_list = results.filter(
                        (a) => a.post_id && a.post_id !== '' && a.host_id === currentUser.user_id
                    );
                    let room_list = results.filter(
                        (a) => a.assemble_id && a.assemble_id !== '' && a.host_id === currentUser.user_id
                    );
                    const likesAudios = audio_list.map(data => data.likes_gained).reduce((partialSum, a) => partialSum + a, 0);
                    const likesPosts = post_list.map(data => Number(data.likes_gained || 0)).reduce((partialSum, a) => partialSum + a, 0);
                    const likesRooms = room_list.map(data => Number(data.likes_gained || 0)).reduce((partialSum, a) => partialSum + a, 0);
                    setLikeGainsAudios(likesAudios);
                    setLikeGainsPosts(likesPosts);
                    setLikeGainsRooms(likesRooms);
                }
            } catch (error) {
                console.log('err: ', error);
            }
        }
    };

    return (
        <div className='profile-card'>
            <Row className='justify-content-md-start'>
                <h5>PROFILE</h5>
            </Row>
            <div className='d-flex flex-column flex-lg-row justify-content-md-start profile-wrapper'>
                <div className='d-flex'>
                    <img
                        className='profile-img'
                        src={
                            currentUser?.photo_url !== ''
                                ? currentUser?.photo_url
                                : placeHolderPng
                        }
                        alt='profile'
                    />
                </div>

                <div className='d-flex flex-column flex-grow-1 profile-content'>
                    <div className='profile-name'>
                        {currentUser?.first_name} {currentUser?.last_name}
                    </div>
                    <div className='profile-company'>{currentUser?.company}</div>
                    <div className='profile-role'>
                        {currentUser?.job}
                    </div>
                    <div className='profile-bio'>{currentUser?.short_bio}</div>
                    <div className='mt-3 profile-likes'>
                        {/* <FontAwesomeIcon icon={faThumbsUp} />
                        <span className='ml-2 font-weight-bold'>{likeGainsAudios} likes for audios you have submitted</span>
                        <br></br>
                        <FontAwesomeIcon icon={faThumbsUp} />
                        <span className='ml-2 font-weight-bold'>{likeGainsPosts} likes for posts you have submitted</span>
                        <br></br> */}
                        <FontAwesomeIcon icon={faThumbsUp} />
                        <span className='ml-2 font-weight-bold'>{likeGainsRooms} likes for rooms you have hosted</span>
                    </div>
                    <div className='d-flex justify-content-end profile-btns'>
                        {/* {currentClub?.user_role !== 'admin' &&
                            currentUser.user_role !== 'admin' &&
                            currentClub?.user_role !== 'manager' && (
                                <Button
                                    variant='primary'
                                    size='sm'
                                    style={{ marginRight: 16 }}
                                    onClick={() => {
                                        history.push('/user/payment');
                                    }}
                                >
                                    {customer
                                        ? 'UPDATE PAYMENT INFO'
                                        : 'ADD PAYMENT INFO'}
                                </Button>
                            )} */}

                        <Button
                            variant='primary'
                            size='sm'
                            onClick={() => {
                                if (currentUser.user_role === 'admin') {
                                    history.push('/admin/profile');
                                } else {
                                    history.push('/user/profile');
                                }
                                // else if (currentClub.user_role === 'manager') {
                                //     history.push('/manager/profile');
                                // } else if (currentClub.user_role === 'user') {
                                //     history.push('/user/profile');
                                // }
                            }}
                        >
                            EDIT PROFILE
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileCard;
