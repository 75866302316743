export const awsImageConfig = {
    bucketName: 'assembly2-image',
    region: 'us-east-1',
    accessKeyId: 'AKIA4MKADVXNOAPNLHGJ',
    secretAccessKey: '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',
    s3Url: 'https://assembly2-image.s3.amazonaws.com' /* optional */,
};

export const awsAudioConfig = {
    bucketName: 'assembly2',
    dirName: 'audio',
    region: 'us-east-1',
    accessKeyId: 'AKIA4MKADVXNOAPNLHGJ',
    secretAccessKey: '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',
    s3Url: 'https://assembly2.s3.amazonaws.com' /* optional */,
};
