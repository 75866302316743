import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import { EventEmitter } from './events';
import moment from 'moment-timezone';

export default {
    showToast: function (title, message, type_code) {
        let type = 'default';

        switch (type_code) {
        case 1:
            type = 'success';
            break;
        case 2:
            type = 'warning';
            break;
        case 3:
            type = 'danger';
            break;
        case 4:
            type = 'info';
            break;
        default:
            type = 'default';
            break;
        }

        store.addNotification({
            title: title,
            message: message,
            type: type, // 'default', 'success', 'info', 'warning'
            container: 'top-right', // where to position the notifications
            animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
            animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
            dismiss: {
                duration: 3000,
            },
        });
    },
    startLoader: function () {
        EventEmitter.dispatch('loader', true);
    },
    stopLoader: function () {
        EventEmitter.dispatch('loader', false);
    },
    isURL: function (url) {
        const pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(Www|wWw|wwW|WWw|WwW|wWW|WWW|www)\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|(Www|wWw|wwW|WWw|WwW|wWW|WWW|www)\.[a-zA-Z0-9]+\.[^\s]{2,})/;
        return pattern.test(url);
    },
    getCurrentLocalTime: function (time) {
        const currentTimeZone = moment.tz.guess();
        console.log(currentTimeZone);
        const currentLocalTime = moment.tz(time, currentTimeZone);
        console.log(currentLocalTime);
        const currentConvertedTime = currentLocalTime
            .clone()
            .tz('America/Los_Angeles')
            .utc()
            .format();
        console.log(currentConvertedTime);
        return currentConvertedTime;
    },
    getUri: function (uri = '') {
        if (uri.includes('http')) {
            return uri;
        } else {
            return `http://${uri}`;
        }
    },
    getDisplayName: function (text, frontSign = '@') {
        if (text && text !== '') {
            return `${frontSign}${text.replace(/\s/g, '').trim()}`;
        }
        return '';
    },
    getTime: function (date) {
        return moment(date).format('YYYY/MM/DD: HH:mm');
    },
    assembleStarted: function (item) {
        const startTime = moment.tz(item.start_time, 'America/Los_Angeles');
        const currrentTime = moment().tz('America/Los_Angeles');
        const isStarted =
            item.is_immediately || startTime.unix() < currrentTime.unix();
        return isStarted;
    },

    validateEmail: function (email) {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(String(email).toLowerCase());
    },

    validatePassword: function (password) {
        const pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
        return pattern.test(String(password));
    },

    toHHMM: function (mins) {
        var hours = Math.floor(mins / 60);
        var minutes = Math.floor(mins - (hours * 60));

        if (hours < 10) { hours = '0' + hours; }
        if (minutes < 10) { minutes = '0' + minutes; }
        return hours + ':' + minutes;
    },

    toMins: function (HHMM) {
        var a = HHMM.split(':'); // split it at the colons

        // Hours are worth 60 minutes.
        var minutes = (+a[0]) * 60 + (+a[1]);
        return minutes;
    },

    validateHHMM: function (HHMM) {
        const pattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        return pattern.test(HHMM);
    },

    addMinutes: function (date, minutes) {
        return new Date(date.getTime() + minutes * 60000);
    },

    roundTimeQuarterHour: function (time) {
        var timeToReturn = new Date(time);
        var minutes = (((timeToReturn.getMinutes() + 14.99) / 15 | 0) * 15) % 60;
        var hours = ((((timeToReturn.getMinutes() / 90) + .5) | 0) + timeToReturn.getHours()) % 24;
        timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
        timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
        timeToReturn.setMinutes(minutes);
        timeToReturn.setHours(hours);
        return timeToReturn;
    },

    disablePastDt: function (current) {
        const yesterday = moment().subtract(1, 'day');
        return current.isAfter(yesterday);
    },

    toHHMMSS: function (value) {
        var sec_num = parseInt(value, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
    
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours+':'+minutes+':'+seconds;
    }
};
