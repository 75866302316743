import React, { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import helper from '../../../../services/helper.service';
import './styles.scss';
import { checkURL } from '../../../database/Model';
import PanigationComp, { PERPAGE } from '../Pagination';
import session from '../../../../services/session.service';
const MAX_WIDTH = '100%';
const R = require('ramda');

function CarouselItem({
    item,
    club_data,
    updating,
    handleEdit,
    handleDelete,
    handleReject,
    handleRemove,
    handleApprove,
    handleUpdateHelloAudio,
    handleUpdateAllAccess,
}) {
    const club = club_data;
    const currentUser = session.get('futureof-user');
    const currentClub = session.get('futureof-club');

    const [more, setMore] = useState(false);
    const badgeBgColor = item.audio_status === 'submitted' ? 'red' : item.audio_status === 'approved' ? 'green' : '';
    const badgeText = item.audio_status === 'submitted' ? 'SUBMITTED' : item.audio_status === 'approved' ? 'LIVE ON APP' : '';
    const handleMore = () => {
        setMore(!more);
    };

    return (
        <div className='d-flex flex-column flex-lg-row user-item'>
            <div
                className='d-flex flex-column justify-content-end align-items-center audio-image'
                style={{
                    backgroundImage: `url(${checkURL(item?.photo_url)
                        ? item?.photo_url
                        : club.voice_photo_url
                        })`,
                }}
            >
                <span
                    style={{
                        textAlign: 'center',
                        backgroundColor: badgeBgColor,
                        width: '100%',
                        paddingRight: 10,
                        paddingLeft: 10,
                        display: 'block',
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        whiteSpace: 'pre-line',
                    }}
                >
                    {badgeText}
                </span>
            </div>

            <div
                className='flex-column'
                style={{ padding: 10, width: '300px' }}
            >
                <Row className='justify-content-center align-items-center'>
                    <Col
                    // style={{
                    //     width: '100%',
                    //     maxWidth: MAX_WIDTH,
                    //     display: 'block',
                    //     wordWrap: 'break-word',
                    // }}
                    >
                        TOPIC: {item.audio_name}
                    </Col>
                </Row>
                <Row className='justify-content-center '>
                    <Col
                        style={{
                            // width: '100%',
                            // maxWidth: MAX_WIDTH,
                            // display: 'block',
                            // wordWrap: 'break-word',
                            textTransform: 'initial',
                        }}
                    >
                        CREATED: {helper.getDisplayName(item.host_name)}
                    </Col>
                </Row>

                <Row className='justify-content-center '>
                    <Col
                        style={{
                            // width: '100%',
                            // maxWidth: MAX_WIDTH,
                            // display: 'block',
                            // wordWrap: 'break-word',
                            textTransform: 'initial',
                        }}
                    >
                        DATE: {helper.getTime(item.created_at)}
                    </Col>
                </Row>

                <Row className='justify-content-center '>
                    <Col
                        style={{
                            // width: '100%',
                            // maxWidth: MAX_WIDTH,
                            // display: 'block',
                            // wordWrap: 'break-word',
                            textTransform: 'initial',
                        }}
                    >
                        #LIKES: {item.likes_gained}
                    </Col>
                </Row>

                <Row className='justify-content-center '>
                    <Col
                        style={{
                            // width: '100%',
                            // maxWidth: MAX_WIDTH,
                            // display: 'block',
                            // wordWrap: 'break-word',
                            textTransform: 'initial',
                        }}
                    >
                        STATUS: {item.audio_status}
                    </Col>
                </Row>
                {item.reject_reason && item.audio_status === 'rejected' && (
                    <Row className='justify-content-center '>
                        <Col
                            style={{
                                width: '100%',
                                maxWidth: MAX_WIDTH,
                                display: 'block',
                                wordWrap: 'break-word',
                                textTransform: 'initial',
                            }}
                        >
                            COMMENT: {item.reject_reason}
                        </Col>
                    </Row>
                )}

                {/* <Row className='justify-content-center'>
                    <Col md='auto'>
                        <Row className='justify-content-center align-items-center'>
                            <Form.Check
                                type={'checkbox'}
                                checked={item.is_sent_message}
                                readOnly                                
                            />
                            <span style={{ marginLeft: 4 }}>Message to All Members</span>
                        </Row>
                    </Col>
                    <Col></Col>
                </Row> */}
            </div>

            <Row className='flex-column' style={{ padding: 10, width: '300px' }}>
                <Row className='justify-content-center align-items-center'>
                    <Col>
                        DESCRIPTION:
                    </Col>
                </Row>
                <Row
                    className='justify-content-center'
                    style={{ marginTop: 10 }}
                >
                    <Col style={{ textTransform: 'initial' }}>
                        {
                            item.description.substring(
                                0,
                                200
                            )
                        }
                    </Col>
                </Row>
            </Row>

            <Row className='justify-content-start align-items-end mb-1 ml-auto'>
                {/* <Col
                    xs={6}
                    md={6}
                    className='d-flex flex-column justify-content-end'
                >
                    <Button
                        type='button'
                        variant='outline-dark'
                        className='btn'
                        size='sm'
                        onClick={() => {
                            handleMore();
                        }}
                    >
                        {more ? 'Less' : 'More'}
                    </Button>
                </Col> */}
                <Col
                    xs={6}
                    md={6}
                    className='d-flex flex-column justify-content-end'
                >
                    {currentClub.user_role === 'user' ? item.host_id === currentUser.user_id && (
                        <div className='d-flex flex-column'
                            style={{
                                height: '100%',
                            }}
                        >
                            <Button
                                type='button'
                                className='btn btn-primary'
                                size='sm'
                                onClick={() => {
                                    handleEdit(item.audio_id);
                                }}
                            >
                                Edit
                            </Button>

                            <Button
                                type='button'
                                className='btn btn-primary'
                                style={{ marginTop: 16 }}
                                size='sm'
                                onClick={() => {
                                    handleDelete(item.audio_id);
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                    ) : (
                        <div className='d-flex flex-column'
                            style={{
                                height: '100%',
                            }}
                        >
                            {item.audio_status !== 'approved' &&
                                item.audio_status === 'submitted' && (
                                    <Button
                                        type='button'
                                        className='btn btn-primary'
                                        size='sm'
                                        onClick={() => {
                                            handleApprove(item.audio_id);
                                        }}
                                    >
                                        {'Approve'}
                                    </Button>
                                )}

                            {item.audio_status !== 'approved' &&
                                item.audio_status === 'submitted' && (
                                    <Button
                                        type='button'
                                        className='btn btn-primary mt-1'
                                        style={{ marginTop: 8 }}
                                        size='sm'
                                        onClick={() => {
                                            handleReject(item.audio_id);
                                        }}
                                    >
                                        {'Reject'}
                                    </Button>
                                )}

                            <Button
                                type='button'
                                className='btn btn-primary mt-1'
                                size='sm'
                                style={{ marginTop: 8 }}
                                onClick={() => {
                                    handleEdit(item.audio_id);
                                }}
                            >
                                Edit
                            </Button>

                            <Button
                                type='button'
                                className='btn btn-primary mt-1'
                                style={{ marginTop: 8 }}
                                size='sm'
                                onClick={() => {
                                    handleDelete(item.audio_id);
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
}

function Carousel({
    data,
    club_data,
    loading,
    updating,
    handleEdit,
    handleDelete,
    handleReject,
    handleRemove,
    handleApprove,
    handleReorderItems,
    handleUpdateHelloAudio,
    handleUpdateAllAccess,
}) {
    const [curPage, setCurPage] = React.useState(0);
    const splitData = R.splitEvery(PERPAGE, data);

    const nextPage = () => setCurPage(curPage + 1);
    const prevPage = () => setCurPage(curPage - 1);
    const changePage = (pageNum) => setCurPage(pageNum);

    return (
        <>
            {data.length > 0 ? (
                <Row>
                    {splitData[curPage].map((item) => (
                        <Col
                            xs={12}
                            md={12}
                            lg={12}
                            key={item.audio_id}
                            className='px-1'
                            style={{ minWidth: (210 * 4) / 3 }}
                        >
                            <CarouselItem
                                key={item.audio_id}
                                club_data={club_data}
                                item={item}
                                updating={updating}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                handleReject={handleReject}
                                handleRemove={handleRemove}
                                handleApprove={handleApprove}
                                handleUpdateHelloAudio={handleUpdateHelloAudio}
                                handleUpdateAllAccess={handleUpdateAllAccess}
                            />
                        </Col>
                    ))}
                    <PanigationComp
                        totalData={splitData}
                        curPage={curPage}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        changePage={changePage}
                    />
                </Row>
            ) : loading ? (
                <p className='text-center'>Loading...</p>
            ) : (
                <p className='text-center'>NO DATA</p>
            )}
        </>
    );
}

export default Carousel;
