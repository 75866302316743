import React from 'react';
import { Row } from 'react-bootstrap';
import './styles.scss';

import adminApi from '../../../services/admin.service';
import session from '../../../services/session.service';
import environment from '../../../environments';
import Content from '../../../components/content';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';

const stripePromise = loadStripe(environment.STRIPE_PUBLIC);

class Payment extends React.Component {
    constructor(props) {
        super();
        this.state = {
            customer: null,
            price_id: '',
        };
    }

    componentDidMount() {
        this.getCustomer();
        this.getAllClubTiers();
    }

    getCustomer = async () => {
        const user = session.get('futureof-user');
        try {
            const res = await adminApi.getCustomer(user.user_id);
            if (res.status) {
                this.setState({
                    customer: res.data,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    getAllClubTiers = () => {
        const club = session.get('futureof-club');
        adminApi
            .getAllClubTiers()
            .then((response) => {
                if (response.status === true) {
                    const results = response.data;
                    const tier = results.find(
                        (item) => item.clubtier_name === club.memebership
                    );

                    console.log('tier: ', tier);

                    this.setState({
                        price_id: tier.price_id
                    });
                }
            })
            .catch((error) => {
                console.log('err: ', error);
            });
    };

    render() {
        const user = session.get('futureof-user');
        return (
            <Content>
                <div className='payment-list'>
                    <Row className='justify-content-start'>
                        <h4>PAYMENT INFORMATION</h4>
                    </Row>
                    <div className='payment-list-container'>
                        <Elements stripe={stripePromise}>
                            <CheckoutForm
                                user={user}
                                customerObj={this.state.customer}
                                price_id={this.state.price_id}
                            />
                        </Elements>
                    </div>
                </div>
            </Content>
        );
    }
}

export default Payment;
