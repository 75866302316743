import React, { createRef } from 'react';
import { Form, Button, Row, Col, InputGroup, Image } from 'react-bootstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import './styles.scss';

import adminApi from '../../../services/admin.service';
import session from '../../../services/session.service';
import helper from '../../../services/helper.service';
import { handleUploadToS3 } from '../../../services/upload.service';
import environment from '../../../environments';
import Content from '../../../components/content';

import UserCarousel from '../components/Carousel/users';
import { ImageForm } from '../components/ImageForm';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const form_attries = [
    {
        name: 'assemble_name',
        label: 'TOPIC',
    },
    {
        name: 'description',
        label: 'DESCRIPTION',
        type: 'textarea',
    },
    {
        name: 'photo_url',
        label: 'PHOTO (OR THE CLUB DEFAULT IMAGE WILL BE USED)',
        type: 'image',
        aspect: 4 / 3,
    },
    {
        name: 'is_immediately',
        label: 'START THE ROOM NOW',
        type: 'check',
    },
    {
        name: 'start_time',
        label: 'WHEN',
        type: 'date',
    },

    // {
    //     name: '',
    //     label: 'GUESTS',
    //     type: 'label',
    // },
    {
        name: 'is_enter_stage',
        label: 'MEMBERS JOIN ON STAGE',
        type: 'check',
    },
];

const assembleKeys = [
    'assemble_name',
    'description',
    'start_time',
    'host_id',
    'host_name',
    'photo_url',
    'is_immediately',
    'is_allow_all',
    'is_enter_stage',
    'enter_club_id',
    'enter_club_name',
];

class CreateAssembly extends React.Component {
    constructor(props) {
        super();
        const club = session.get('futureof-club');
        this.state = {
            assemble_name: '',
            description: '',
            photo_url: '',
            is_immediately: true,
            is_allow_all: true,
            is_enter_stage: true,
            start_time: new Date().toISOString(),
            start_time_date: moment().format('YYYY-MM-DD'),
            start_time_hour: moment().format('hh:mm A'),
            host_id: '',
            host_name: '',
            enter_club_id: club.club_id,
            enter_club_name: club.club_name,
            validated: false,
            loading: false,
            imgFileSrc: null,
            users: [],
            submitTitle: 'Submit',
            pagenumber: 0,
            roomImages: [],
            select_photo: '',
        };
        this.createRefs();
    }

    componentDidMount() {
        const club = session.get('futureof-club');
        this.createRefs();
        this.getAllUsers();
        this.getRoomImagesByClubId(club.club_id);
    }

    createRefs = () => {
        form_attries.map((attr) => {
            this[`${attr.name}Ref`] = createRef();
            return 0;
        });
    };

    getAllUsers = () => {
        adminApi
            .getAllUsers()
            .then((response) => {
                if (response.status === true) {
                    const results = response.data;
                    const users = results.filter(item => item.user_id);
                    this.setState({
                        users,
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                console.log('err: ', error);
                this.setState({
                    loading: false,
                });
            });
    };

    getRoomImagesByClubId = async club_id => {
        try {
            const response = await adminApi.getRoomImagesByClubId(club_id);
            if (response && response.status) {
                if (response.data && response.data.length > 0) {
                    this.setState({
                        roomImages: response.data,
                    });
                } else {
                    this.setState({
                        roomImages: []
                    });
                }
            }
        } catch (error) {
            console.log('@@@@@ ', error);
        }
    };

    handleBack = () => {
        const club = session.get('futureof-club');
        const currentUser = session.get('futureof-user');
        if (this.state.pagenumber === 1) {
            this.setState({
                pagenumber: 0,
            });
        } else {
            if (currentUser.user_role === 'admin') {
                this.props.history.push('/admin/assemblies');
            } else {
                this.props.history.push('/user/assemblies');
            }
        }
    };

    handleCancel = () => {
        const club = session.get('futureof-club');
        const currentUser = session.get('futureof-user');
        if (currentUser.user_role === 'admin') {
            this.props.history.push('/admin/assemblies');
        } else {
            this.props.history.push('/user/assemblies');
        }
    };

    handleSwitchChange = (event) => {
        const target = event.target;
        const value = target.checked;
        const name = target.name;
        console.log(name, value);
        this.setState({
            [name]: value,
        });
    };

    handleFileChange = (blob, name) => {
        // const previewUrl = window.URL.createObjectURL(blob);
        this.setState({
            imgFileSrc: blob,
            select_photo: '',
        });
    };

    handleDateChange = (date) => {
        if (!date) {
            helper.showToast('Warning', 'Please choose valid date and time', 2);
        } else {
            if (Date.parse(date)) {
                this.setState({
                    start_time_date: date.format('YYYY-MM-DD'),
                    start_time: `${date.format('YYYY-MM-DD')}T${moment(
                        this.state.start_time
                    ).format('HH:mm:ss')}`
                });
            } else {
                helper.showToast(
                    'Warning',
                    'Please choose valid date and time',
                    2
                );
            }
        }
    };

    handleTimeChange = (date) => {
        if (!date) {
            helper.showToast('Warning', 'Please choose valid date and time', 2);
        } else {
            if (Date.parse(date)) {
                const start_time = `${this.state.start_time_date}T${date.format('HH:mm:ss')}`;
                this.setState({
                    start_time_hour: date.format('HH:mm:ss'),
                    start_time: start_time
                });
            } else {
                helper.showToast(
                    'Warning',
                    'Please choose valid date and time',
                    2
                );
            }
        }
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    };

    handleNext = async () => {
        const { start_time, is_immediately } = this.state;
        if (this.state.pagenumber === 0) {
            for (const idx in form_attries) {
                const attr = form_attries[idx];
                if (
                    attr.name !== '' &&
                    attr.type !== 'check' &&
                    attr.type !== 'image'
                ) {
                    const value = this.state[attr.name];
                    if (!is_immediately && moment(helper.roundTimeQuarterHour(start_time)) < moment()) {
                        helper.showToast('Error', `The start time of the room should be in future`, 3);
                        return;
                    }
                    if (value !== undefined && value !== '') {
                        this.setState({ validated: true });
                    } else {
                        if (
                            attr.name === 'photo_url' &&
                            this.state.imgFileSrc !== null
                        ) {
                            continue;
                        }
                        this.setState({ validated: true });
                        console.log(attr.name, value);
                        helper.showToast(
                            'Warning',
                            'Please fill out all info',
                            2
                        );
                        return;
                    }
                }
            }
        }

        if (this.state.pagenumber === 1) {
            if (this.state.host_id === '') {
                helper.showToast('Warning', 'Please choose one host', 2);
                return;
            }
        }

        this.setState(
            {
                pagenumber: this.state.pagenumber + 1,
            },
            async () => {
                if (this.state.pagenumber > 1) {
                    await this.handleSubmit();
                }
            }
        );
    };

    handlePrev = () => {
        this.setState({
            pagenumber:
                this.state.pagenumber < 0 ? 0 : this.state.pagenumber - 1,
        });
    };

    handleSelectHost = (item, selected) => {
        if (selected) {
            this.setState({
                host_id: '',
                host_name: '',
            });
        } else {
            this.setState({
                host_id: item.user_id,
                host_name: `${item.first_name} ${item.last_name}`,
            });
        }
    };

    handleSubmit = async (event) => {
        const { submitTitle, select_photo, start_time } = this.state;
        const user = session.get('futureof-user');
        if (submitTitle !== 'Submit') {
            return;
        }
        this.setState({ submitTitle: 'Processing...' });
        if (select_photo !== '') {
            await this.setState({
                photo_url: select_photo
            });
        } else if (this.state.imgFileSrc != null) {
            const photo_url = await handleUploadToS3(
                this.state.imgFileSrc,
                Date.now().toString(),
                environment.ratio4_3
            );
            console.log('upload res', photo_url);
            await this.setState({
                photo_url,
            });
        }
        this.setState({ submitTitle: 'Updating...' });
        const updateObj = {};
        for (const idx in assembleKeys) {
            const key = assembleKeys[idx];
            const value = this.state[key];
            if (value !== undefined && value !== '') {
                updateObj[key] = value;
            } else {
                // Either both of one
                if (key === 'photo_url') {
                    continue;
                }
                console.log(key, value);
                this.setState({ validated: true });
                helper.showToast('Warning', 'Please fill out all info', 2);
                return;
            }
        }
        updateObj['start_time'] = helper.getCurrentLocalTime(
            moment(helper.roundTimeQuarterHour(start_time))
        );
        updateObj['from_web'] = true;
        updateObj['user_name'] = `${user.first_name} ${user.last_name}`;
        updateObj['user_id'] = user.user_id;
        this.createAssembly(updateObj);
    };

    createAssembly = (updateObj) => {
        console.log('updateObj: ', updateObj);
        adminApi
            .createAssemble(updateObj)
            .then((response) => {
                this.setState({ submitTitle: 'Success' });
                setTimeout(() => {
                    this.setState({ submitTitle: 'Submit' });
                }, 2000);
                if (response.status === true && response.data) {
                    helper.showToast(
                        'Success',
                        'Room created successfully.',
                        1
                    );
                    this.handleBack();
                } else {
                    helper.showToast('Error', 'Failed to create the room', 3);
                    this.handleBack();
                }
            })
            .catch((error) => {
                this.setState({ submitTitle: 'Failed' });
                setTimeout(() => {
                    this.setState({ submitTitle: 'Submit' });
                }, 2000);
                console.log('err: ', error);
                helper.showToast(
                    'Error',
                    error.message || 'Failed to create the room',
                    3
                );
                this.handleBack();
            });
    };

    onKeyPress = (event) => {
        if (event.charCode === 13) {
            this.btn.click();
        }
    }

    renderRow = (form_attr) => {
        const { is_immediately, start_time } = this.state;
        const timeConstraints = {
            hours: {
                min: moment(helper.roundTimeQuarterHour(start_time)).hour()
            },
            minutes: {
                // min: (moment(helper.roundTimeQuarterHour(start_time)).hour() === moment().hour()) ? moment(helper.roundTimeQuarterHour(start_time)).minute() : 0,
                step: 15
            }
        };

        return (
            <Form.Group
                key={form_attr.name}
                as={Col}
                controlId={`${form_attr.name}`}
                className='justify-content-md-center input-row assemble-form'
            >
                {
                    form_attr.type !== 'check' ? form_attr.type === 'date' ? !is_immediately && (
                        <Form.Label as={Col}>{form_attr.label}</Form.Label>
                    ) : (
                        <Form.Label as={Col}>{form_attr.label}</Form.Label>
                    ) : null
                }

                <InputGroup as={Col} className='input-area'>
                    {form_attr.type === 'image' ? (
                        <div className='d-flex overflow-auto'>
                            <ImageForm
                                ref={this[`${form_attr.name}Ref`]}
                                previewSrc={this.state.imgFileSrc}
                                name={form_attr.name}
                                initWidth={80}
                                aspect={form_attr.aspect}
                                changeFileSrc={this.handleFileChange}
                            />
                            {
                                this.state.select_photo === '' &&
                                this.state.imgFileSrc !== null && (
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        style={{
                                            position: 'absolute',
                                            top: 5,
                                            left: 5,
                                            fontSize: 22,
                                            color: '#66ff00',
                                        }}
                                    />
                                )
                            }
                            {
                                this.renderImages()
                            }
                        </div>
                    ) : form_attr.type === 'label' ? null : form_attr.type ===
                        'check' ? (
                        <Form.Check
                            ref={this[`${form_attr.name}Ref`]}
                            inline
                            type='checkbox'
                            id={form_attr.name}
                            name={form_attr.name}
                            checked={this.state[form_attr.name]}
                            onChange={this.handleSwitchChange}
                            label={form_attr.label}
                        />
                    ) : form_attr.type === 'date' ? !is_immediately && (
                        <>
                            <Datetime
                                className='datetimepicker'
                                dateFormat='YYYY-MM-DD'
                                timeFormat={false}
                                isValidDate={helper.disablePastDt}
                                initialValue={moment(helper.roundTimeQuarterHour(start_time)).format('YYYY-MM-DD')}
                                onChange={this.handleDateChange}
                                onClose={this.handleDateChange}
                            />
                            <div style={{ width: 20 }}></div>
                            <Datetime
                                className='datetimepicker'
                                dateFormat={false}
                                timeFormat='hh:mm A'
                                // initialValue={moment(
                                //     start_time
                                // ).format('hh:mm A')}
                                initialValue={moment(helper.roundTimeQuarterHour(start_time)).format('hh:mm A')}
                                timeConstraints={timeConstraints}
                                onChange={this.handleTimeChange}
                                onClose={this.handleTimeChange}
                            />
                        </>
                    ) : form_attr.type === 'textarea' ? (
                        <Form.Control
                            ref={this[`${form_attr.name}Ref`]}
                            as='textarea'
                            rows={5}
                            maxLength={1000}
                            required
                            defaultValue={this.state[form_attr.name]}
                            name={form_attr.name}
                            onChange={this.handleInputChange}
                        />
                    ) : (
                        <Form.Control
                            ref={this[`${form_attr.name}Ref`]}
                            required
                            type='text'
                            defaultValue={this.state[form_attr.name]}
                            name={form_attr.name}
                            onChange={this.handleInputChange}
                            onKeyPress={this.onKeyPress}
                        />
                    )}
                </InputGroup>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
        );
    };

    renderImages = () => {
        const { roomImages } = this.state;
        if (roomImages.length > 0) {
            return (
                <div className='d-flex p-1 pl-3'>
                    {
                        roomImages.map((item, index) => this.renderImageItem(item, index))
                    }
                </div>
            )
        }

    };

    renderImageItem = (item, index) => {
        return (
            <div className='d-flex position-relative'
                key={index}
                onClick={() => {
                    this.setState({
                        select_photo: item.photo_url,
                    });
                }}
            >
                <Image
                    src={item.photo_url}
                    style={{
                        width: 200,
                        height: 200 * 0.75,
                        borderRadius: 6,
                        marginRight: 10,
                    }}
                />
                {item.photo_url === this.state.select_photo && (
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{
                            position: 'absolute',
                            top: 5,
                            left: 5,
                            fontSize: 22,
                            color: '#66ff00',
                        }}
                    />
                )}
            </div>
        );
    };

    render() {
        const { submitTitle, loading, pagenumber, users, host_id } = this.state;
        const currentUser = session.get('futureof-user');
        const selectedUsers = users.filter(
            (user) =>
                user.user_id === host_id && user.user_id !== currentUser.user_id
        );
        const deselectedUsers = users.filter(
            (user) =>
                user.user_id !== host_id && user.user_id !== currentUser.user_id
        );
        const reorderedUsers = [
            currentUser,
            ...selectedUsers,
            ...deselectedUsers,
        ];
        let pageOrder = pagenumber + 1;
        if (pageOrder > 2) {
            pageOrder = 2;
        }
        return (
            <Content>
                <div className='assemble-list'>
                    <Row className='justify-content-start'>
                        <h4>ROOMS</h4>
                    </Row>
                    <div className='assemble-list-container'>
                        <Row className='justify-content-start'>
                            <h5>START ROOM (STEP {pageOrder} OF 2)</h5>
                        </Row>
                        {pagenumber === 1 && (
                            <Row className='justify-content-start'>
                                <h6>SELECT HOST</h6>
                            </Row>
                        )}
                        <Col>
                            {!loading ? (
                                <div>
                                    {pagenumber === 0 ? (
                                        form_attries.map((attr) =>
                                            this.renderRow(attr)
                                        )
                                    ) : pagenumber === 1 ? (
                                        <UserCarousel
                                            data={reorderedUsers}
                                            selected_id={host_id}
                                            onSelect={this.handleSelectHost}
                                        />
                                    ) : (
                                        <p className='text-center'>
                                            Updating...
                                        </p>
                                    )}
                                    {pagenumber < 2 && (
                                        <Row className='justify-content-start'>
                                            <Button
                                                variant={'primary'}
                                                type={
                                                    pagenumber === 1
                                                        ? 'submit'
                                                        : 'button'
                                                }
                                                className='btn mt-5'
                                                onClick={() => {
                                                    this.handleNext();
                                                }}
                                                ref={node => (this.btn = node)}
                                            >
                                                {pagenumber === 1
                                                    ? submitTitle
                                                    : 'Next'}
                                            </Button>
                                            <Button
                                                type='button'
                                                className='btn btn-primary mt-5'
                                                style={{
                                                    marginLeft: 30,
                                                    backgroundColor: 'white',
                                                    color: '#3B75B4',
                                                }}
                                                onClick={this.handleBack}
                                            >
                                                {pagenumber === 0
                                                    ? 'Cancel'
                                                    : 'Back'}
                                            </Button>
                                            {pagenumber === 1 && (
                                                <Button
                                                    type='button'
                                                    className='btn btn-primary mt-5'
                                                    style={{
                                                        marginLeft: 30,
                                                        backgroundColor:
                                                            'white',
                                                        color: '#3B75B4',
                                                    }}
                                                    onClick={this.handleCancel}
                                                >
                                                    Cancel
                                                </Button>
                                            )}
                                        </Row>
                                    )}
                                </div>
                            ) : (
                                <p className='text-center'>Loading...</p>
                            )}
                        </Col>
                    </div>
                </div>
            </Content>
        );
    }
}

export default CreateAssembly;
