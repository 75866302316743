import React from 'react';
import './stepper.scss';

function Stepper({ isActive = 0, numOfStep1 = 3, numOfStep2 = 5 }) {
    const stepper1 = () => {
        let stepItems = [];
        for (var i = 0; i < numOfStep1; i++) {
            if (isActive === i) {
                stepItems.push(
                    <div className='step step-active step1' key={i}>
                        <div>
                            <div className='circle'></div>
                        </div>
                    </div>
                );
            } else {
                stepItems.push(
                    <div className='step step1' key={i}>
                        <div>
                            <div className='circle'></div>
                        </div>
                    </div>
                );
            }

        }
        return stepItems;
    }

    const stepper2 = () => {
        let stepItems = [];
        for (var i = 0; i < numOfStep2; i++) {
            if (isActive === numOfStep1 + i) {
                stepItems.push(
                    <div className='step step-active step2' key={numOfStep1 + i}>
                        <div>
                            <div className='circle'></div>
                        </div>
                    </div>
                );
            } else {
                stepItems.push(
                    <div className='step step2' key={numOfStep1 + i}>
                        <div>
                            <div className='circle'></div>
                        </div>
                    </div>
                );
            }

        }
        return stepItems;
    }

    return (
        <div className='stepper'>
            <div className='container'>
                <div className='title'>STEP {isActive + 1}/8</div>
                <div className='content'>
                    {
                        stepper1()
                    }
                    {
                        stepper2()
                    }
                </div>
            </div>

        </div>
    );
}

export default Stepper;