export default {
    set: function (key, obj) {
        localStorage.setItem(key, JSON.stringify(obj));
    },
    get: function (key) {
        return JSON.parse(localStorage.getItem(key));
    },
    del: function (key) {
        localStorage.removeItem(key);
        window.location.reload();
    },
    clear: function () {
        localStorage.clear();
    },
};
