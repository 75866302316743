export default {
    // domain: 'http://localhost:3000',
    baseApi: 'https://api.climateassembly.io/admin/api',
    // baseApi: 'http://localhost:8000/admin/api',
    userbaseApi: 'https://api.climateassembly.io/api',
    // userbaseApi: 'http://localhost:8000/api',
    cdnlink: 'https://d13jpbk4diaqdg.cloudfront.net/',
    cdnlink_audio: 'https://d26o2kejsry7rj.cloudfront.net',
    ratio4_3: '1080x810',
    ratio4_5: '810x1012',
    ratio1_1: '1012x1012',
    ratio4_1: '1200x300',
    ratio16_3: '1600x300',
    PERPAGE: 5,
    STRIPE_PUBLIC:
        'pk_test_51IE6BbAs4ndOQycIbarKbo0OhuIQv4DpiuVEWH5S6Vhflsc0HYLxn6QtIZAb4RwdfAxlknTSBE73GSwH0b9D6r7C00bdpfXn0S',
    STRIPE_CLIENT_ID: 'ca_JImqDA10vqOpmLvjesnWWntaOWHTd0SX',
    STRIPE_SECRET_KEY:
        'sk_test_51IE6BbAs4ndOQycI91hVEhpTmLQ4ylQmPLWkhcXmDpa4MmG37w50ORehmaKnXqZqDeJ8JWKiPlrFikNWF7H2DCyF00mCeKf8BY',
    GOOGLE_API_KEY: 'AIzaSyA_RvdzS2iC-Tn9aIQzTdrKyBJhJof7lHw',
    STREAM_API_KEY: 'kadshcbfxwgp',
    STREAM_SECRET_KEY:
        'mck7r5cnr47dpvt5wm4vmjtpth9ujp3unejz8quk6ez224xakyv2jgw2maqr5vjn',
    TINYMCEKEY: 'kfn1et0k7sgq1qq5adiew2z5e52en73zjtbs0zrk0o3ts4d9'
};
