import React from 'react';
import '../tbl-clubs.scss';

import { Form, Modal, Button } from 'react-bootstrap';

import adminApi from '../../../services/admin.service';
import helper from '../../../services/helper.service';

import { event as DataModel } from '../Model';

import Header from '../components/header';
import TableComp from '../components/table';

const model_keys = Object.keys(DataModel);
const filter_keys = model_keys.filter(
    (r) => DataModel[r] === 'String' && !`${r}`.includes('_url')
);
const main_key = 'event_id';
class Events extends React.Component {
    constructor(props) {
        super();
        this.state = {
            data_list: [],
            loading: true,
            search: '',
            filterItem: filter_keys[0],
            showModal: false,
            edit_id: '',
            editied_key: '',
            sorted: {},
        };
    }

    componentDidMount() {
        this.getAllEvents();
    }

    getAllEvents = () => {
        adminApi
            .getAllEvents()
            .then((response) => {
                if (response.status === true) {
                    var results = response.data;
                    this.setState({
                        data_list: results,
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                console.log('err: ', error);
                this.setState({
                    loading: false,
                });
            });
    };

    handleChangeFilter = (event) => {
        const filterItem = event.target.value;
        this.setState({
            filterItem,
        });
    };

    handleChangeSearch = (event) => {
        const search = event.target.value;
        this.setState({
            search,
        });
    };

    handleChangeBoolVal = (event) => {

    };

    handleUpdateTableItem = (event) => {

    };

    handleShowModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    onDeleteItem = (del_id) => {
        if (del_id) {
            adminApi
                .deleteEvent(del_id)
                .then((response) => {
                    if (response.status === true) {
                        this.setState({
                            data_list: this.state.data_list.filter(
                                (data) => data.event_id !== del_id
                            ),
                        });
                        helper.showToast(
                            'Success',
                            'Event deleted successfully',
                            1
                        );
                    } else {
                        helper.showToast('Error', response.data, 3);
                    }
                })
                .catch((error) => {
                    console.log('err: ', error);
                    helper.showToast(
                        'Error',
                        error.message ||
                        'There was an error while deleting the event.',
                        3
                    );
                });
        }
    };

    onDeleteBulk = async (delIds, callBack = () => { }) => {
        const promiseList = [];
        for (const del_id of delIds) {
            promiseList.push(adminApi.deleteEvent(del_id));
        }
        await Promise.all(promiseList);
        this.getAllEvents();
        callBack();
    };

    render() {
        const {
            search,
            filterItem,
            data_list,
            loading,
            showModal,
            editied_key,
        } = this.state;
        return (
            <div className='tbl-clus'>
                <div className='content'>
                    <Header
                        title='Event'
                        filterItem={filterItem}
                        filter_keys={filter_keys}
                        handleChangeFilter={this.handleChangeFilter}
                        handleChangeSearch={this.handleChangeSearch}
                    />
                    <TableComp
                        data_list={data_list}
                        dataModel={DataModel}
                        filterItem={filterItem}
                        search={search}
                        loading={loading}
                        main_key={main_key}
                        perPage={5}
                        onChangeBoolValue={this.handleChangeBoolVal}
                        onChangeStrValue={this.handleUpdateTableItem}
                        onDeleteItem={this.onDeleteItem}
                        onDeleteBulk={this.onDeleteBulk}
                    />
                </div>
            </div>
        );
    }
}

export default Events;
