import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Row, Col, InputGroup, Modal, Tabs, Tab } from 'react-bootstrap';
import './styles.scss';

import adminApi from '../../../services/admin.service';
import helper from '../../../services/helper.service';
import Content from '../../../components/content';

const TxtGen = (props) => {
    const [query, setQuery] = useState('');
    const [text1, setText1] = useState('write five independent patent claims');
    const [text2, setText2] = useState('');
    const [genTxt, setGenTxt] = useState('');
    const [isGenerate, setIsGenerate] = useState(false);

    const [promptTokens, setPromptTokens] = useState(0);
    const [completionTokens, setCompletionTokens] = useState(0);

    const [modalShow, setModalShow] = useState(false);
    const [model, setModel] = useState('gpt-4');
    const [ai_type, setAIType] = useState('chat');
    const [temperature, setTemperature] = useState(0.7);
    const [max_tokens, setMaxTokens] = useState(256);

    const [key, setKey] = useState('general');

    const queryRef = useRef();

    const ai_types = ['chat', 'text'];
    const chat_models = ['gpt-4', 'gpt-4-0314', 'gpt-4-32k', 'gpt-4-32k-0314', 'gpt-3.5-turbo', 'gpt-3.5-turbo-0301'];
    const text_models = ['text-davinci-003', 'text-davinci-002', 'text-curie-001', 'text-babbage-001,', 'text-ada-001'];
    const [models, setModels] = useState(chat_models);
    const [seperateLines, setSeperateLines] = useState([]);

    useEffect(() => {
        if (key === 'claims') {
            if (text1 !== '') {
                if (text2 !== '') {
                    setQuery(text1 + ' ' + text2);
                } else {
                    setQuery(text1);
                }
            } else {
                if (text2 !== '') {
                    setQuery(text1 + ' ' + text2);
                } else {
                    setQuery(text2);
                }
            }
        }
    }, [key]);

    const handleInputChange = (e) => {
        const target = e.target;
        if (key === 'general') {
            setQuery(target.value);
        } else {
            const name = target.name;
            const value = target.value;
            if (name === 'text1') {
                setText1(value);
                if (value === '') {
                    setQuery(text2)
                } else {
                    if (text2 === '') {
                        setQuery(value);
                    } else {
                        setQuery(value + ' ' + text2);
                    }
                }
            } else if (name === 'text2') {
                setText2(value);
                if (value === '') {
                    setQuery(text1)
                } else {
                    if (text1 === '') {
                        setQuery(value);
                    } else {
                        setQuery(text2 + ' ' + value);
                    }
                }
            }
        }
    };

    const generateText = async () => {
        try {
            setIsGenerate(!isGenerate);
            if (!isGenerate) {
                setGenTxt('');
                const body = {
                    query,
                    ai_type,
                    model,
                    temperature,
                    max_tokens
                }
                console.log('body: ', body);
                const response = await adminApi.txtGen(body);
                if (response && response.status) {
                    setIsGenerate(false);
                    var seperateLines = [];
                    if (ai_type === 'chat') {
                        console.log('response.data.choices: ', response.data.choices[0].message.content);
                        setGenTxt(response.data.choices[0].message.content);
                        var seperateLines = response.data.choices[0].message.content.split(/\r?\n|\r|\n/g);
                    } else if (ai_type === 'text') {
                        console.log('response.data.choices: ', response.data.choices[0].text);
                        setGenTxt(response.data.choices[0].text);
                        var seperateLines = response.data.choices[0].text.split(/\r?\n|\r|\n/g);
                    }
                    console.log('separateLines: ', seperateLines.length);
                    setSeperateLines(seperateLines);
                    setPromptTokens(response.data.usage.prompt_tokens);
                    setCompletionTokens(response.data.usage.completion_tokens);
                }
            } else {
                return;
            }
        } catch (error) {
            console.log('generate text error: ', error);
            helper.showToast('Error', 'Failed to generate the text', 3);
            setIsGenerate(false);
        }

    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(genTxt).then(
            () => {
                helper.showToast('Success', 'The text is copied to clipboard successfully', 1);
            },
            () => {
                helper.showToast('Error', 'Failed to copy the text to clipboard', 3);
            }
        );
    };

    const appendToClipboard = () => {
        navigator.clipboard.readText()
            .then(text => {
                console.log('Pasted content: ', text);
                navigator.clipboard.writeText((text + genTxt)).then(
                    () => {
                        helper.showToast('Success', 'The text is appended to clipboard successfully', 1);
                    },
                    () => {
                        helper.showToast('Error', 'Failed to append the text to clipboard', 3);
                    }
                );
            })
            .catch(err => {
                helper.showToast('Error', 'Failed to append the text to clipboard', 3);
            });
    };

    const append = (line) => {
        navigator.clipboard.readText()
            .then(text => {
                console.log('Pasted content: ', text);
                navigator.clipboard.writeText((text + line)).then(
                    () => {
                        helper.showToast('Success', 'The text is appended to clipboard successfully', 1);
                    },
                    () => {
                        helper.showToast('Error', 'Failed to append the text to clipboard', 3);
                    }
                );
            })
            .catch(err => {
                helper.showToast('Error', 'Failed to append the text to clipboard', 3);
            });
    };

    const hideModal = () => {
        setModalShow(false);
    };

    const handleTemperature = (e) => {
        setTemperature(Number(e.target.value));
    };

    const handleMaxLength = (e) => {
        setMaxTokens(Number(e.target.value));
    };

    const handleChangeAIType = (e) => {
        setAIType(e.target.value);
        if (e.target.value === 'text') {
            setModels(text_models);
            setModel(text_models[0]);
        } else if (e.target.value === 'chat') {
            setModels(chat_models);
            setModel(chat_models[0]);
        }
    };

    const handleChangeModel = (e) => {
        setModel(e.target.value);
    };

    const handleChangeKey = (k) => {
        setKey(k);
        setQuery('');
        setGenTxt('');
        setSeperateLines([]);
        setPromptTokens(0);
        setCompletionTokens(0);
    }

    return (
        <Content>
            <div className='txtgen-edit'>
                <Row className='justify-content-md-start'>
                    <h4>TEXT GENERATE</h4>
                </Row>
                <Form.Group>
                    <Row className='justify-content-center min-vh-100'>
                        <div></div>
                        <Button
                            variant={isGenerate ? 'secondary' : 'primary'}
                            size='lg'
                            className='btn genBtn'
                            onClick={() => {
                                setModalShow(true);
                            }}
                        >
                            GENERATE
                        </Button>
                        <div></div>
                    </Row>
                </Form.Group>
            </div>
            <Modal
                show={modalShow}
                onHide={() => {
                    hideModal();
                }}
                // size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                dialogClassName='modal-90w podcast-search'
            >
                <Modal.Header closeButton>
                    <Modal.Title className='text-white'>
                        TEXT GENERATE
                    </Modal.Title>
                    <div className='database-tab ml-3'>
                        <Tabs defaultActiveKey='general' activeKey={key} onSelect={(k) => handleChangeKey(k)}>
                            <Tab eventKey='general' title='General'>
                            </Tab>
                            <Tab eventKey='claims' title='Claims'>
                            </Tab>
                        </Tabs>
                    </div>
                </Modal.Header>
                <Modal.Body className='modal-body search-Modal'>
                    <div className='txtgen-edit'>
                        <Form.Group>
                            <div className='d-flex justify-content-between flex-column flex-lg-row'>
                                <Form.Label className='text-white'>Query</Form.Label>
                                <div className='d-flex flex-column'>
                                    <Form.Control as='select' onChange={handleChangeAIType} className='text-white' value={ai_type}>
                                        {ai_types.map((k) => (
                                            <option key={k} value={k}>
                                                {k}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </div>
                                <div className='d-flex flex-column'>
                                    <Form.Control as='select' onChange={handleChangeModel} className='text-white' value={model}>
                                        {models.map((k) => (
                                            <option key={k} value={k}>
                                                {k}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </div>
                                <div className='d-flex flex-column' style={{ minWidth: 300 }}>
                                    <div className='d-flex justify-content-between'>
                                        <Form.Label as={Col} className='text-white'>Temperature</Form.Label>
                                        <Form.Label as={Col} className='text-white text-right'>{temperature}</Form.Label>
                                    </div>
                                    <input type='range' step={0.01} min={0} max={1} value={temperature} onChange={handleTemperature} />
                                </div>
                                <div className='d-flex flex-column' style={{ minWidth: 300 }}>
                                    <div className='d-flex justify-content-between'>
                                        <Form.Label as={Col} className='text-white'>Maximum length</Form.Label>
                                        <Form.Label as={Col} className='text-white text-right'>{max_tokens}</Form.Label>
                                    </div>
                                    <input type='range' step={1} min={0} max={4000} value={max_tokens} onChange={handleMaxLength} />
                                </div>
                                <Button
                                    variant={isGenerate ? 'secondary' : 'primary'}
                                    className='btn mb-2'
                                    onClick={() => {
                                        generateText()
                                    }}
                                >
                                    {
                                        isGenerate ? 'CANCEL' : 'GENERATE'
                                    }
                                </Button>
                            </div>
                            {
                                key === 'general' ?
                                    <Form.Control
                                        ref={queryRef}
                                        as='textarea'
                                        rows={5}
                                        maxLength={1000}
                                        required
                                        name={'query'}
                                        style={{ color: 'white' }}
                                        onChange={handleInputChange}
                                    />
                                    :
                                    <div>
                                        <Form.Group
                                            as={Col}
                                            className='justify-content-md-center input-row assemble-form'
                                        >
                                            <InputGroup as={Col} className='input-area align-items-center'>
                                                <Form.Label as={Col} className='text-white'>Text 1: </Form.Label>
                                                <Form.Control
                                                    as='textarea'
                                                    rows={5}
                                                    maxLength={1000}
                                                    required
                                                    name={'text1'}
                                                    value={text1}
                                                    style={{ color: 'white' }}
                                                    onChange={handleInputChange}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            className='justify-content-md-center input-row assemble-form'
                                        >
                                            <InputGroup as={Col} className='input-area align-items-center'>
                                                <Form.Label as={Col} className='text-white'>Text 2: </Form.Label>
                                                <Form.Control
                                                    as='textarea'
                                                    rows={5}
                                                    maxLength={1000}
                                                    required
                                                    name={'text2'}
                                                    value={text2}
                                                    style={{ color: 'white' }}
                                                    onChange={handleInputChange}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </div>

                            }
                        </Form.Group>
                        {
                            genTxt !== '' &&
                            <div className='d-flex flex-column justify-content-md-start'>
                                <div className='genTxt'>Tokens in prompt: {promptTokens}</div>
                                <div className='genTxt'>Tokens in response: {completionTokens}</div>
                            </div>
                        }
                        {
                            genTxt !== '' &&
                            <div>
                                {
                                    key === 'claims' && seperateLines.length > 0 ?
                                        seperateLines.map(line => {
                                            if (line !== '') {
                                                return (
                                                    <div className='d-flex'>
                                                        <Button
                                                            variant={'primary'}
                                                            className='btn mt-2 mb-2 mr-2'
                                                            onClick={() => {
                                                                append(line)
                                                            }}
                                                        >
                                                            Append
                                                        </Button>
                                                        <div className='genTxt'>{line}</div>
                                                    </div>
                                                )
                                            }
                                        })
                                        :
                                        <div className='genTxt'>{genTxt}</div>
                                }
                                {
                                    key === 'general' &&
                                    <div className='d-flex justify-content-end'>
                                        <Button
                                            variant={'primary'}
                                            className='btn mt-2 mb-2 mr-2'
                                            onClick={() => {
                                                appendToClipboard()
                                            }}
                                        >
                                            Append to Clipboard
                                        </Button>
                                        <Button
                                            variant={'primary'}
                                            className='btn mt-2 mb-2'
                                            onClick={() => {
                                                copyToClipboard()
                                            }}
                                        >
                                            Copy to Clipboard
                                        </Button>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </Content>
    );
};

export default TxtGen;