import React, { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import helper from '../../../../services/helper.service';
import './styles.scss';
import { checkURL } from '../../../database/Model';
import PanigationComp, { PERPAGE } from '../Pagination';
import session from '../../../../services/session.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

const MAX_WIDTH = '100%';
const R = require('ramda');

function CarouselItem({
    item,
    club_data,
    updating,
    handleEdit,
    handleDelete,
    handleReject,
    handleApprove
}) {
    const club = club_data;
    const currentUser = session.get('futureof-user');
    const currentClub = session.get('futureof-club');

    const [more, setMore] = useState(false);
    const badgeBgColor = item.post_status === 'submitted' ? 'red' : item.post_status === 'approved' ? 'green' : '';
    const badgeText = item.post_status === 'submitted' ? 'SUBMITTED' : item.post_status === 'approved' ? 'LIVE ON APP' : '';
    const handleMore = () => {
        setMore(!more);
    };

    return (
        <div className='d-flex flex-column flex-lg-row user-item'>
            <div
                className='d-flex flex-column position-relative align-items-center event-image'
                style={{
                    backgroundImage: `url(${checkURL(item?.photo_url)
                        ? item?.photo_url
                        : club.voice_photo_url
                        })`,
                    cursor: item.link ? 'pointer' : 'default'
                }}
                onClick={() => {
                    if (item.link.includes('http')) {
                        window.open(item.link);
                    } else {
                        window.open('http://' + item.link);
                    }
                }}
            >
                {
                    item.link && <div
                        className='d-flex flex-column align-items-center justify-content-center position-absolute'
                        onClick={() => {
                            if (item.link.includes('http')) {
                                window.open(item.link);
                            } else {
                                window.open('http://' + item.link);
                            }
                        }}
                        style={{
                            textAlign: 'center',
                            width: '80px',
                            height: '80px',
                            cursor: 'pointer',
                            backgroundColor: 'grey',
                            borderRadius: '16px',
                            opacity: '0.8',
                            top: '50%',
                            transform: 'translate(0, -50%)'
                        }}
                    >
                        <FontAwesomeIcon icon={faLink} style={{ transform: 'scale(2)' }} />
                        <p style={{ fontSize: '12px', marginTop: '14px', marginBottom: '0px' }}>{'LINK'}</p>
                    </div>
                }
                <span
                    style={{
                        textAlign: 'center',
                        backgroundColor: badgeBgColor,
                        width: '100%',
                        paddingRight: 10,
                        paddingLeft: 10,
                        display: 'block',
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        whiteSpace: 'pre-line',
                        position: 'absolute',
                        bottom: '0%'
                    }}
                >
                    {badgeText}
                </span>
            </div>

            <div
                className='d-flex flex-column flex-grow-1'
                style={{ padding: 10, width: '300px' }}
            >
                <Row className='justify-content-center '>
                    <Col
                        style={{
                            textTransform: 'initial',
                        }}
                    >
                        TOPIC: {item.topic}
                    </Col>
                </Row>
                <Row className='justify-content-center '>
                    <Col
                        style={{
                            // width: '100%',
                            // maxWidth: MAX_WIDTH,
                            // display: 'block',
                            // wordWrap: 'break-word',
                            textTransform: 'initial',
                        }}
                    >
                        POSTED BY: {helper.getDisplayName(item.user?.first_name + ' ' + item.user?.last_name)}
                    </Col>
                </Row>

                <Row className='justify-content-center '>
                    <Col
                        style={{
                            // width: '100%',
                            // maxWidth: MAX_WIDTH,
                            // display: 'block',
                            // wordWrap: 'break-word',
                            textTransform: 'initial',
                        }}
                    >
                        DATE: {helper.getTime(item.created_at)}
                    </Col>
                </Row>

                <Row className='justify-content-center '>
                    <Col
                        style={{
                            // width: '100%',
                            // maxWidth: MAX_WIDTH,
                            // display: 'block',
                            // wordWrap: 'break-word',
                            textTransform: 'initial',
                        }}
                    >
                        #LIKES: {item.likes_gained}
                    </Col>
                </Row>

                <Row className='justify-content-center '>
                    <Col
                        style={{
                            // width: '100%',
                            // maxWidth: MAX_WIDTH,
                            // display: 'block',
                            // wordWrap: 'break-word',
                            textTransform: 'initial',
                        }}
                    >
                        STATUS: {item.post_status}
                    </Col>
                </Row>
            </div>

            <Row className='flex-column' style={{ padding: 10, width: '300px' }}>
                <Row className='justify-content-center align-items-center'>
                    <Col>
                        DESCRIPTION:
                    </Col>
                </Row>
                <Row
                    className='justify-content-center'
                    style={{ marginTop: 10 }}
                >
                    <Col style={{ textTransform: 'initial' }}>
                        {
                            item.description.substring(
                                0,
                                200
                            )
                        }
                    </Col>
                </Row>
            </Row>

            <Row className='justify-content-start align-items-end mb-1 ml-auto'>
                {/* <Col
                    xs={6}
                    md={6}
                    className='d-flex flex-column justify-content-end'
                >
                    <Button
                        type='button'
                        variant='outline-dark'
                        className='btn'
                        size='sm'
                        onClick={() => {
                            handleMore();
                        }}
                    >
                        {more ? 'Less' : 'More'}
                    </Button>
                </Col> */}
                <Col
                    xs={6}
                    md={6}
                    className='d-flex flex-column justify-content-end'
                >
                    {currentClub.user_role === 'user' ? item.host_id === currentUser.user_id && (
                        <div className='d-flex flex-column'
                            style={{
                                height: '100%',
                            }}
                        >
                            <Button
                                type='button'
                                className='btn btn-primary'
                                size='sm'
                                onClick={() => {
                                    handleEdit(item.post_id);
                                }}
                            >
                                Edit
                            </Button>

                            <Button
                                type='button'
                                className='btn btn-primary'
                                style={{ marginTop: 16 }}
                                size='sm'
                                onClick={() => {
                                    handleDelete(item.post_id);
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                    ) : (
                        <div className='d-flex flex-column'
                            style={{
                                height: '100%',
                            }}
                        >
                            {item.post_status !== 'approved' &&
                                item.post_status === 'submitted' && (
                                    <Button
                                        type='button'
                                        className='btn btn-primary'
                                        size='sm'
                                        onClick={() => {
                                            handleApprove(item.post_id);
                                        }}
                                    >
                                        {'Approve'}
                                    </Button>
                                )}

                            {item.post_status !== 'approved' &&
                                item.post_status === 'submitted' && (
                                    <Button
                                        type='button'
                                        className='btn btn-primary mt-1'
                                        style={{ marginTop: 8 }}
                                        size='sm'
                                        onClick={() => {
                                            handleReject(item.post_id);
                                        }}
                                    >
                                        {'Reject'}
                                    </Button>
                                )}
                            <Button
                                type='button'
                                className='btn btn-primary mt-1'
                                size='sm'
                                style={{ marginTop: 8 }}
                                onClick={() => {
                                    handleEdit(item.post_id);
                                }}
                            >
                                Edit
                            </Button>

                            <Button
                                type='button'
                                className='btn btn-primary mt-1'
                                style={{ marginTop: 8 }}
                                size='sm'
                                onClick={() => {
                                    handleDelete(item.post_id);
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
}

function Carousel({
    data,
    club_data,
    loading,
    updating,
    handleEdit,
    handleDelete,
    handleReject,
    handleRemove,
    handleApprove,
    handleReorderItems,
}) {
    const [curPage, setCurPage] = useState(0);
    const splitData = R.splitEvery(PERPAGE, data);

    const nextPage = () => setCurPage(curPage + 1);
    const prevPage = () => setCurPage(curPage - 1);
    const changePage = (pageNum) => setCurPage(pageNum);

    return (
        <>
            {data.length > 0 ? (
                <Row>
                    {splitData[curPage].map((item) => (
                        <Col
                            xs={12}
                            md={12}
                            lg={12}
                            key={item.post_id}
                            className='px-1'
                            style={{ minWidth: (210 * 4) / 3 }}
                        >
                            <CarouselItem
                                key={item.post_id}
                                club_data={club_data}
                                item={item}
                                updating={updating}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                handleReject={handleReject}
                                handleRemove={handleRemove}
                                handleApprove={handleApprove}
                            />
                        </Col>
                    ))}
                    <PanigationComp
                        totalData={splitData}
                        curPage={curPage}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        changePage={changePage}
                    />
                </Row>
            ) : loading ? (
                <p className='text-center'>Loading...</p>
            ) : (
                <p className='text-center'>NO DATA</p>
            )}
        </>
    );
}

export default Carousel;
