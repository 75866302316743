import React, { createRef } from 'react';
import { Form, Button, Row, Col, InputGroup, Image } from 'react-bootstrap';

import './styles.scss';

import adminApi from '../../../services/admin.service';
import session from '../../../services/session.service';
import helper from '../../../services/helper.service';
import {
    handleUploadToS3,
    handleUploadAudioToS3,
} from '../../../services/upload.service';
import environment from '../../../environments';

import { ImageForm } from '../components/ImageForm';
import Content from '../../../components/content';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const form_attries = [
    {
        name: 'topic',
        label: `TOPIC`,
    },
    {
        name: 'description',
        label: `What's up?`,
        type: 'textarea',
    },
    {
        name: 'photo_url',
        label: 'PHOTO (OR THE CLUB DEFAULT IMAGE WILL BE USED)',
        type: 'image',
        aspect: 4 / 3,
    },
    {
        name: 'link',
        label: 'LINK(OPTIONAL)',
    },
];

const postKeys = [
    'topic',
    'description',
    'photo_url',
    'link',
    'enter_club_id',
    'enter_club_name',
];

class EditPost extends React.Component {
    constructor(props) {
        super();
        const currentUser = session.get('futureof-user');
        const club = session.get('futureof-club');
        this.state = {
            description: '',
            photo_url: '',
            is_allow_all: true,
            enter_club_id: club.club_id,
            enter_club_name: club.club_name,
            validated: false,
            loading: false,
            imgFileSrc: null,
            submitTitle: 'Submit',
            roomImages: [],
            select_photo: '',
        };
        this.postIdRef = createRef();
        this.postIdRef.current = null;
        this.createRefs();
    }

    componentDidMount() {
        const club = session.get('futureof-club');
        this.getPostDetails();
        this.getRoomImagesByClubId(club.club_id);
    }

    createRefs = () => {
        form_attries.map((attr) => {
            this[`${attr.name}Ref`] = createRef();
            return 0;
        });
        this.podcastRef = createRef();
        this.podcastPlayRef = createRef();
    };

    getPostDetails = () => {
        this.setState({ loading: true });
        const currentUser = session.get('futureof-user');
        const club = session.get('futureof-club');
        const post_id = club && club.user_role === 'manager' ? window.location.href.split('/manager/posts/edit/')[1] : window.location.href.split('/user/posts/edit/')[1];
        this.postIdRef.current = post_id;
        console.log('post_id edit ==>', this.postIdRef.current);
        adminApi
            .getPost(post_id)
            .then((response) => {
                this.setState({ loading: false });
                console.log('post_id edit res ==>', response);
                if (response.status === true) {
                    const post = response.data;
                    console.log(post);
                    postKeys.map((attr) => {
                        this.setState({
                            [attr]: post[attr],
                        });
                        return 0;
                    });
                } else {
                    helper.showToast('Error', response.data, 3);
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                helper.showToast(
                    'Error',
                    error.message || 'There is an error while getting post.',
                    3
                );
                console.log('err: ', error);
            });
    };

    getRoomImagesByClubId = async club_id => {
        try {
            const response = await adminApi.getRoomImagesByClubId(club_id);
            if (response && response.status) {
                if (response.data && response.data.length > 0) {
                    this.setState({
                        roomImages: response.data,
                    });
                } else {
                    this.setState({
                        roomImages: []
                    });
                }
            }
        } catch (error) {
            console.log('@@@@@ ', error);
        }
    };

    handleBack = () => {
        const currentUser = session.get('futureof-user');
        const club = session.get('futureof-club');
        if (club && club.user_role === 'manager') {
            this.props.history.push('/manager/posts');
        } else if (club && club.user_role === 'user') {
            this.props.history.push('/user/posts');
        }
    };

    handleFileChange = (blob, name) => {
        this.setState({
            imgFileSrc: blob,
        });
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    };

    handleSwitchChange = (event) => {
        const target = event.target;
        const value = target.checked;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    };

    handleSubmit = async (post) => {
        const { submitTitle, select_photo } = this.state;
        const currentUser = session.get('futureof-user');
        const club = session.get('futureof-club');
        if (submitTitle !== 'Submit') {
            return;
        }
        if (select_photo !== '') {
            await this.setState({
                photo_url: select_photo
            });
        } else if (this.state.imgFileSrc != null) {
            const photo_url = await handleUploadToS3(
                this.state.imgFileSrc,
                Date.now().toString(),
                environment.ratio4_3
            );
            console.log('upload res', photo_url);
            await this.setState({
                photo_url,
            });
        }
        const updateObj = {};
        for (const idx in postKeys) {
            const key = postKeys[idx];
            const value = this.state[key];
            if (value !== undefined && value !== '') {
                if (key === 'link') {
                    if (!helper.isURL(value)) {
                        helper.showToast('Error', 'Please enter a valid link.', 3);
                        return;
                    }
                }
                updateObj[key] = value;
            } else {
                // Either both of one
                if (key === 'photo_url') {
                    continue;
                }
                if (key === 'link') {
                    continue;
                }
                this.setState({ validated: true });
                helper.showToast('Warning', 'Please fill out all info', 2);
                return;
            }
        }
        this.setState({ submitTitle: 'Updating...' });
        updateObj['is_allow_all'] = true;
        updateObj['from_web'] = true;
        if (club && club.user_role === 'user') {
            updateObj['post_status'] = 'submitted';
        }
        updateObj['user_role'] = club.user_role;
        this.updatePost(updateObj);
    };

    updatePost = (updateObj) => {
        if (this.postIdRef.current) {
            adminApi
                .updatePost(this.postIdRef.current, updateObj)
                .then((response) => {
                    this.setState({ submitTitle: 'Success' });
                    setTimeout(() => {
                        this.setState({ submitTitle: 'Submit' });
                    }, 2000);
                    if (response.status === true && response.data) {
                        helper.showToast(
                            'Success',
                            'Post updated successfully.',
                            1
                        );
                        this.handleBack();
                    } else {
                        helper.showToast(
                            'Error',
                            'Failed to update the post',
                            3
                        );
                    }
                })
                .catch((error) => {
                    this.setState({ submitTitle: 'Failed' });
                    setTimeout(() => {
                        this.setState({ submitTitle: 'Submit' });
                    }, 2000);
                    console.log('err: ', error);
                    helper.showToast(
                        'Error',
                        error.message || 'Failed to update the post',
                        3
                    );
                });
        }
    };

    onKeyPress = (event) => {
        if (event.charCode === 13) {
            this.btn.click();
        }
    }

    renderImages = () => {
        const { roomImages } = this.state;
        if (roomImages.length > 0) {
            return (
                <div className='d-flex p-1 pl-3'>
                    {
                        roomImages.map((item, index) => this.renderImageItem(item, index))
                    }
                </div>
            )
        }

    };

    renderImageItem = (item, index) => {
        return (
            <div className='d-flex position-relative'
                key={index}
                onClick={() => {
                    this.setState({
                        select_photo: item.photo_url,
                    });
                }}
            >
                <Image
                    src={item.photo_url}
                    style={{
                        width: 200,
                        height: 200 * 0.75,
                        borderRadius: 6,
                        marginRight: 10,
                    }}
                />
                {item.photo_url === this.state.select_photo && (
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{
                            position: 'absolute',
                            top: 5,
                            left: 5,
                            fontSize: 22,
                            color: '#66ff00',
                        }}
                    />
                )}
            </div>
        );
    };

    renderRow = (form_attr) => {
        return (
            <Form.Group
                key={form_attr.name}
                as={Col}
                controlId={`${form_attr.name}`}
                className='justify-content-md-center input-row post-form'
            >
                {(form_attr.type !== 'check' && form_attr.type !== 'audio') && (
                    <Form.Label as={Col}>{form_attr.label}</Form.Label>
                )}
                <InputGroup as={Col} className='input-area'>
                    {form_attr.type === 'image' ? (
                        <div className='d-flex overflow-auto'>
                            <ImageForm
                                ref={this[`${form_attr.name}Ref`]}
                                previewSrc={this.state.imgFileSrc}
                                name={form_attr.name}
                                initWidth={80}
                                aspect={form_attr.aspect}
                                changeFileSrc={this.handleFileChange}
                            />
                            {
                                this.state.select_photo === '' &&
                                this.state.imgFileSrc !== null && (
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        style={{
                                            position: 'absolute',
                                            top: 5,
                                            left: 5,
                                            fontSize: 22,
                                            color: '#66ff00',
                                        }}
                                    />
                                )
                            }
                            {
                                this.renderImages()
                            }
                        </div>
                    ) : form_attr.type === 'textarea' ? (
                        <Form.Control
                            ref={this[`${form_attr.name}Ref`]}
                            as='textarea'
                            rows={5}
                            maxLength={1000}
                            required
                            defaultValue={this.state[form_attr.name]}
                            name={form_attr.name}
                            onChange={this.handleInputChange}
                        />
                    ) : form_attr.type === 'check' ? (
                        <Form.Check
                            ref={this[`${form_attr.name}Ref`]}
                            type='switch'
                            id={form_attr.name}
                            name={form_attr.name}
                            checked={this.state[form_attr.name]}
                            onChange={this.handleSwitchChange}
                            label={'NO/YES'}
                        />
                    ) : (
                        <Form.Control
                            ref={this[`${form_attr.name}Ref`]}
                            required
                            type='text'
                            defaultValue={this.state[form_attr.name]}
                            name={form_attr.name}
                            onChange={this.handleInputChange}
                            onKeyPress={this.onKeyPress}
                        />
                    )}
                </InputGroup>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
        );
    };

    render() {
        const { loading } = this.state;
        const club = session.get('futureof-club');
        const post_id = club && club.user_role === 'manager' ? window.location.href.split('/manager/posts/edit/')[1] : window.location.href.split('/user/posts/edit/')[1];
        return (
            <Content>
                <div className='audio-list'>
                    <Row className='justify-content-start'>
                        <h4>POST</h4>
                    </Row>
                    <div className='audio-list-container'>
                        <Row className='justify-content-start'>
                            <h5>EDIT POST</h5>
                        </Row>
                        <Col style={{ marginTop: 30 }}>
                            {!loading ? (
                                <div>
                                    {form_attries.map((attr) =>
                                        this.renderRow(attr)
                                    )}
                                    <Row className='justify-content-start'>
                                        <Button
                                            variant={'primary'}
                                            type={'submit'}
                                            className='btn mt-5'
                                            onClick={() => {
                                                this.handleSubmit();
                                            }}
                                            ref={node => (this.btn = node)}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            type='button'
                                            className='btn btn-primary mt-5'
                                            style={{
                                                marginLeft: 30,
                                                backgroundColor: 'white',
                                                color: '#3B75B4',
                                            }}
                                            onClick={this.handleBack}
                                        >
                                            Cancel
                                        </Button>
                                    </Row>
                                </div>
                            ) : (
                                <p className='text-center'>Loading...</p>
                            )}
                        </Col>
                    </div>
                </div>
            </Content>
        );
    }
}

export default EditPost;
