import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import helper from '../../../../services/helper.service';
import adminApi from '../../../../services/admin.service';
import './styles.scss';
import { checkURL } from '../../../database/Model';
import PanigationComp, { PERPAGE } from '../Pagination';
import session from '../../../../services/session.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';

const R = require('ramda');
const MAX_WIDTH = '100%';

function CarouselItem({
    item,
    handleEdit,
    handleDelete,
    handleCalendarEvent,
    handleUpdateAllAccess,
    handleNotify
}) {
    const eventTime = moment.tz(item.event_time, 'America/Los_Angeles');
    const eventDate = eventTime.format('L');
    const eventHour = eventTime.format('hh:mm A z');
    const currentUser = session.get('futureof-user');
    const club = session.get('futureof-club');
    const [more, setMore] = useState(false);

    const currentTime = moment().tz('America/Los_Angeles');
    const isStarted = eventTime.unix() < currentTime.unix();
    const badgeBgColor = isStarted ? 'green' : '#3B75B4';
    const badgeText = isStarted ? 'EVENT IS LIVE' : '';
    const [user_notified, setUserNotified] = useState(item.notify_users && item.notify_users.includes(currentUser.user_id));
    const [user_declined, setUserDeclined] = useState(item.decline_users && item.decline_users.includes(currentUser.user_id));

    const handleMore = () => {
        setMore(!more);
    };

    const handleNotifyEvent = async (notified) => {
        try {
            const payload = {
                user_role: club.user_role,
                notified
            };
            const data = await adminApi.notifyEvent(item.event_id, payload);
            if (data.status) {
                setUserNotified(!notified);
                window.location.reload();
            }
        } catch (error) {
            console.log('Failed to notify user', error);
        }
    };

    return (
        <div className='d-flex flex-column flex-lg-row user-item'>
            <div
                className='d-flex flex-column justify-content-end align-items-center event-image'
                style={{
                    backgroundImage: `url(${checkURL(item?.photo_url)
                        ? item?.photo_url
                        : club.assemble_photo_url
                        })`,
                }}
            >
                {/* {
                    !isStarted && <div
                        className='d-flex flex-column align-items-center justify-content-center'
                        onClick={() => {
                            handleNotifyEvent(!user_notified);
                        }}
                        style={{
                            position: 'absolute',
                            textAlign: 'center',
                            width: '80px',
                            height: '80px',
                            cursor: 'pointer',
                            backgroundColor: `${user_notified ? 'lightblue' : 'grey'}`,
                            borderRadius: '16px',
                            opacity: '0.8'
                        }}
                    >
                        <FontAwesomeIcon icon={faBell} style={{ transform: 'scale(2)' }} />
                        <p style={{ fontSize: '12px', marginTop: '14px', marginBottom: '0px' }}>{user_notified ? 'UN-RSVP' : 'RSVP'}</p>
                    </div>
                } */}

                <span
                    style={{
                        textAlign: 'center',
                        alignSelf: 'end',
                        backgroundColor: badgeBgColor,
                        width: '100%',
                        paddingRight: 10,
                        paddingLeft: 10,
                        display: 'block',
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        whiteSpace: 'pre-line',
                    }}
                >
                    {badgeText}
                </span>
            </div>

            <div className='d-flex flex-column flex-grow-1'>
                <div className='d-flex flex-column flex-lg-row flex-grow-1'>
                    <div className='d-flex flex-column flex-fill p-2'>
                        <Row className='justify-content-center align-items-center'>
                            <Col
                                style={{
                                    width: '100%',
                                    maxWidth: MAX_WIDTH,
                                    display: 'block',
                                    wordWrap: 'break-word',
                                    textTransform: 'initial',
                                }}
                            >
                                EVENT NAME: {item.event_name}
                            </Col>
                        </Row>
                        <Row className='justify-content-center align-items-center'>
                            <Col
                                style={{
                                    width: '100%',
                                    maxWidth: MAX_WIDTH,
                                    display: 'block',
                                    wordWrap: 'break-word',
                                    textTransform: 'initial',
                                }}
                            >
                                DATE: {eventDate}
                            </Col>
                        </Row>
                        <Row className='justify-content-center align-items-center'>
                            <Col
                                style={{
                                    width: '100%',
                                    maxWidth: MAX_WIDTH,
                                    display: 'block',
                                    wordWrap: 'break-word',
                                    textTransform: 'initial',
                                }}
                            >
                                TIME: {eventHour}
                            </Col>
                        </Row>
                        <Row className='justify-content-center'>
                            <Col
                                style={{
                                    width: '100%',
                                    maxWidth: MAX_WIDTH,
                                    display: 'block',
                                    wordWrap: 'break-word',
                                    textTransform: 'initial',
                                }}
                            >
                                LINK:
                                <a href={item.link ? (item.link.includes('http') || item.link.includes('https')) ? item.link : `https://${item.link}` : '#'} target='_blank'>
                                    {' '}{item.link}
                                </a>
                            </Col>
                        </Row>
                        <div className='d-flex justify-content-start'>
                            CONFIRMED: {item.notify_users ? item.notify_users.length : 0}
                        </div>
                        <div className='d-flex justify-content-start'>
                            DECLINED: {item.decline_users ? item.decline_users.length : 0}
                        </div>
                    </div>

                    <div className='d-flex flex-column flex-fill p-2'>
                        <Row className='justify-content-center align-items-center'>
                            <Col>
                                DESCRIPTION:
                            </Col>
                        </Row>
                        <Row
                            className='justify-content-center'
                            style={{ marginTop: 10 }}
                        >
                            <Col style={{ textTransform: 'initial' }}>
                                {
                                    item.description.substring(
                                        0,
                                        200
                                    )
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
                {
                    club.user_role === 'user' ? isStarted ?
                        <div className='d-flex justify-content-center'>
                            <Button
                                type='button'
                                className='btn btn-primary m-2'
                                size='sm'
                                onClick={() => {
                                    if (item.link && item.link !== '') {
                                        const url = (item.link.includes('http') || item.link.includes('https')) ? item.link : `https://${item.link}`;
                                        window.open(url, '_blank');
                                    }
                                }}
                            >
                                JOIN NOW
                            </Button>
                        </div>
                        :
                        <div className='d-flex justify-content-center'>
                            <Button
                                type='button'
                                className='btn m-2'
                                variant={`${user_notified ? 'secondary' : 'primary'}`}
                                size='sm'
                                onClick={() => {
                                    handleNotifyEvent(!user_notified);
                                }}
                                disabled={user_notified}
                            >
                                I will attend
                            </Button>

                            <Button
                                type='button'
                                className='btn m-2'
                                variant={`${user_declined ? 'secondary' : 'primary'}`}
                                size='sm'
                                onClick={() => {
                                    handleNotifyEvent(!user_notified);
                                }}
                                disabled={user_declined}
                            >
                                Sorry, can't make it
                            </Button>
                        </div>
                        :
                        null
                }
                <div className='d-flex justify-content-end align-items-end mb-1 ml-auto'>
                    {
                        club.user_role === 'manager' && <div
                            className='d-flex flex-column justify-content-end m-2'
                        >

                            <div className='d-flex flex-column w-100'>
                                <Button
                                    type='button'
                                    className='btn btn-primary'
                                    size='sm'
                                    onClick={() => {
                                        handleEdit(item.event_id);
                                    }}
                                >
                                    Edit
                                </Button>

                                <Button
                                    type='button'
                                    className='btn btn-primary mt-1'
                                    style={{ marginTop: 16 }}
                                    size='sm'
                                    onClick={() => {
                                        handleDelete(item.event_id);
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>

                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function Carousel({
    data,
    loading,
    handleEdit,
    handleDelete,
    handleReorderItems,
    handleCalendarEvent,
    handleUpdateAllAccess,
    handleNotify
}) {
    const [curPage, setCurPage] = React.useState(0);
    const splitData = R.splitEvery(PERPAGE, data);

    const nextPage = () => setCurPage(curPage + 1);
    const prevPage = () => setCurPage(curPage - 1);
    const changePage = (pageNum) => setCurPage(pageNum);

    return (
        <>
            {data.length > 0 ? (
                <div>
                    {splitData[curPage].map((item) => (
                        <CarouselItem
                            key={item.event_id}
                            item={item}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleCalendarEvent={handleCalendarEvent}
                            handleUpdateAllAccess={handleUpdateAllAccess}
                            handleNotify={handleNotify}
                        />
                    ))}
                    <PanigationComp
                        totalData={splitData}
                        curPage={curPage}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        changePage={changePage}
                    />
                </div>
            ) : loading ? (
                <p className='text-center'>Loading...</p>
            ) : (
                <p className='text-center'>NO DATA</p>
            )}
        </>
    );
}

export default Carousel;
